<template>
  <v-sheet
    class="py-md-5"
    height="100%"
    color="transparent"
  >
    <div class="text-center mb-5">
      <core-subheading
        class="mb-1"
        v-text="value.name"
      />
      <span
        class="subheading"
        v-text="value.category"
      />
    </div>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <div
          class="mb-3"
          style="max-width: 500px;"
        >
          <core-subheading class="mb-3">
            Project Details
          </core-subheading>
          <core-text v-text="value.details" />
          <core-subheading>
            Project Info
          </core-subheading>
          <v-list class="transparent">
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">
                  mdi-account
                </v-icon>
              </v-list-item-action>
              <v-list-item-title
                class="mr-2"
                v-text="value.client"
              />
              <v-list-item-title class="text-caption grey--text text--lighten-1 hidden-sm-and-down" />
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">
                  mdi-calendar
                </v-icon>
              </v-list-item-action>
              <v-list-item-title
                class="mr-2"
                v-text="value.dates"
              />
              <v-list-item-title class="text-caption grey--text text--lighten-1 hidden-sm-and-down" />
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">
                  mdi-tag
                </v-icon>
              </v-list-item-action>
              <v-chip-group
                :column="$vuetify.breakpoint.smAndDown"
              >
                <v-chip
                  v-for="(tag, i) in tags"
                  :key="i"
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  {{ tag }}
                </v-chip>
              </v-chip-group>
              <v-list-item-title class="text-caption grey--text text--lighten-1 hidden-sm-and-down" />
            </v-list-item>
          </v-list>
        </div>
      </v-col>
      <v-col
        v-if="value.src"
        class="hidden-sm-and-down"
        cols="12"
        md="8"
      >
        <v-img
          :src="require(`@/assets/projects/${value.src}`)"
          height="350"
          width="700"
          contain
          class="mx-auto"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          name: '',
          category: '',
          src: undefined,
        }),
      },
    },
    computed: {
      tags () {
        return this.value.tags.split(',')
      },
    },
  }
</script>
